import React from 'react'
import './header.css';
import color from '../../assets/color.png';
import house from '../../assets/house.png';

const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">PASS is indeed prevalent in Bangladesh's northern region</h1>
        <p>The village's residents, who have few means, may be able to survive if they are helped by an amiable neighborhood. A few sturdy families for an industrialized nation. </p>

        <div className="gpt3__header-content__input">
        
        
          
        </div>

        <div className="gpt3__header-content__color">
          <img src={color} alt="color" />
          <p>Initiative towards a better result</p>
        </div>
      
      </div>
      <div className="gpt3__header-image">
          <img src={house} alt="house" />
      </div>
        
    </div>
  )
}

export default Header