import React from 'react'
import { Feature } from '../../components';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
      <div className="gpt3__whatgpt3-feature">
        <Feature title="About Us" text="For the preceding twenty years, our NGO has been tenaciously embarking to change the appalling conditions that the local community confronts in the northern region of Bangladesh. We are proud to have concluded numerous initiatives of all kinds, ranging from modest community efforts to more significant transformative endeavors, thanks to the knowledge and commitment of our team. With an unwavering dedication to improving the lives of the poor, we have started preparing to broaden our assistance by providing them the information and abilities they need to enhance their plight. Our goal is to equip the poor with the knowledge and resources they need to move toward a prosperous and secure tomorrow." />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">The necessary intervention for enormous success</h1>
        <p>Educate the populace</p>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature title="Sustainable" text="By incorporating sustainability into our fundamental principles and operations, our non-profit organization aspires to have a major and enduring effect on the global community and enable it to thrive for future generations." />
        <Feature title="Innovation" text="The organization we represent focuses on adhering to innovation as a force behind our purpose. We comprehend that addressing challenging social and environmental issues requires ingenuity." />
        <Feature title="Community" text="Our organization has a strong commitment to civic participation and is aware of the immense impact that local groups may have on determining their own futures." />
      </div>

    </div>
  )
}

export default WhatGPT3