import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Exhibitions involving cultural significance create recognition</h4>
      <h1 className="gradient__text">The efficacy with a socioeconomic <br /> focus generate harmony and alteration</h1>
      <p>Our voices may conquer obstacles and connect with an array of people by using tunes, the language of all people, to raise awareness and start debates about important social issues.</p>
      <h4>Novel Ideas and Important Projects</h4>
    </div>
  </div>
);

export default Possibility;
