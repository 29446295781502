import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Aiding villages for robust, long-term possibilities</p>
      <h3>Providing support and inclusive opportunity to improve the lives of the extremely impoverished.</h3>
    </div>
    <div className="gpt3__cta-btn">
    <button type="button">Upgrading Lives</button>
    </div>
  </div>
);

export default CTA;