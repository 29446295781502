import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Strategy determined upon Humanity',
    text: 'Inevitably conduct the role with an elevated level of understanding, aiming to comprehend and relate to the difficulties and triumphs of individuals we assist.',
  },
  {
    title: 'Building Mutual Respect and Trust',
    text: 'We cultivate a climate of respect among ourselves, acceptance, and trust. Every person is viewed with dignity and valued for their innate strengths because we understand their intrinsic worth and potential.',
  },
  {
    title: 'Going Above and Beyond',
    text: 'We adhere to going above and beyond to fulfill the needs of the people we serve. We strive to go above and beyond expectations and demonstrate our genuine empathy and tenderness, whether by providing more resources, advocating on behalf of others, or simply being present to provide comfort.',
  },
  {
    title: 'Engagement and support from the community are soothing.',
    text: 'We regularly look for opportunities to work with neighborhood groups, civic leaders, and volunteers who value sincerity. Together, we can increase our influence and widen our geographical reach, making sure that no one is left behind.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Our organization is centered on compassion, and it infuses every element of what we do.</h1>
      <p>Assistance Focused on People</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;