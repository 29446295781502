import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Advancing innovative, <br /> green development endeavors for the betterment of humanity.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} text="Encouraging concord along with settlement bridges" />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} text="Allow movement towards reducing the effects of environmental degradation" />
        <Article imgUrl={blog03} text="Making certain everyone has access to adequate nourishment" />
        <Article imgUrl={blog04} text="Progressing alternatives to fossil fuels for the future" />
        <Article imgUrl={blog05} text="For an ecologically sound future, viable urban areas must be designed" />
      </div>
    </div>
  </div>
);

export default Blog;
