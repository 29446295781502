import React from 'react';
import logo from '../../assets/logo1.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding" id="footer">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Come together with our organization to enhance social growth and inspire lives</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p>A vision of a better world</p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="logo1" />
        <p> <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4> </h4>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4> </h4>
        <p> </p>
        <p></p>
        <p></p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>H: 54, R: 05, Kamal Kachna, Rangpur</p>
        <p>+8801712225852</p>
        <p>pass.kuri@yahoo.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p></p>
    </div>
  </div>
);

export default Footer;
